@import './constants.scss';

.profile-page {
  margin: 40px 10px;
  background-color: white;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.025);

  @media (min-width: 800px) {
    width: 80%;
    padding: 20px;
    max-width: 600px;
    margin: 50px auto;
  }

  h2 {
    display: block;
    color: $green;
    font-size: 24px;
    line-height: 1.4;
    margin: 0 0 20px;
  }

  h3 {
    font-weight: bold;
    color: black;
    font-size: 16px;
    line-height: 1.4;
    margin: 20px 0 10px;
  }

  .field {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 9px 0;

    & + .field {
      border-top: none;
    }

    h4 {
      margin: 0 0 3px;
      font-size: 14px;
      color: #666;
      font-weight: normal;
    }

    p {
      margin: 0;
      font-size: 16px;
    }
  }
}