.timepicker-js__input {
  display: inline-block;
  text-align: center;
  padding: 4px;
  font-family: monospace;
  user-select: none;
  color: black;
}

.timepicker-js__input + .timepicker-js__input {
  margin-left: 8px;
}

.timepicker-js__input:focus {
  outline: none;
  background-color: #eee;
}

/* .timepicker-js__no-text {
  color: white;
}

.timepicker-js__no-text:focus {
  color: #eee;
} */

.timepicker-js__span {
  display: inline-block;
  padding: 4px 0;
  font-family: monospace;
  user-select: none;
}