@import '../constants.scss';

.admin-list {
  margin: 60px auto;
  width: 95%;
  max-width: 1100px;
  box-sizing: border-box;

  .topbar, .bottombar {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);

    tr:hover {
      background: #fafafa;
    }

    th {
      background: #f0f0f0;
      padding: 11px 15px;
      text-align: left;
    }

    th + th {
      border-left: 1px solid #d0d0d0;
    }

    tr + tr td {
      border-top: 1px solid #d0d0d0;
    }

    td + td {
      border-left: 1px solid #d0d0d0;
    }

    td {
      padding: 11px 15px;
      height: 20px;

      &.bold {
        font-weight: bold;
      }

      span.label {
        margin: 0 10px 0 0;
        color: #888;
      }

      a {
        margin-right: 10px;
      }
    }

    td.no-items {
      text-align: center;
      font-style: italic;
      padding: 40px;
    }
  }
  
  .search-box {
    padding: 9px 14px 7px;
    border: 2px solid $green;
    line-height: 16px;
    border-radius: 18px;
    width: 250px;
    min-width: 0;
    flex: 0 1 auto;
    margin-right: 10px;

    &:focus {
      outline: none;
      border: 2px solid darken($green, 15%);
    }
  }

  .create-btn {
    margin: 0 0 0 auto;
    background: $green;
    color: white;
    text-decoration: none;
    padding: 8px 14px;
    line-height: 16px;
    border-radius: 16px;
    cursor: pointer;
    flex: 0 0 auto;

    .fas {
      display: inline-block;
      font-size: 0.85em;
      margin-left: 4px;
    }

    &:hover {
      background: darken($green, 5%);
    }
  }

  td.actions a {
    cursor: pointer;
    text-decoration: none;
    color: darken($green, 10%);

    &:hover {
      color: darken($green, 20%);
      text-decoration: underline;
    }
  }

  .pagination {
    margin: 0;
    user-select: none;

    span {
      margin-right: 10px;
    }

    .fas {
      display: inline-block;
      padding: 4px 6px;
      border-radius: 3px;
      cursor: pointer;
      color: darken($green, 15%);

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }
}