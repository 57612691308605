@import '../constants.scss';

.star-rating {
    width: 100px;

    .star {
        position: relative;
        margin-right: 2px;
        color: $green;
    }

    i + i {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }

    &.edit i {
        cursor: pointer;
    }
}