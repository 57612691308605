@import './constants.scss';

.jobs, .single-job {
    margin: 40px 10px;

    @media (min-width: 800px) {
        margin: 40px auto;
        padding: 0 40px;
        max-width: 900px;
    }
}

.job {
    position: relative;
    background-color: white;
    border-radius: 8px;
    margin: 10px 0;
    padding: 10px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.025);
    
    @media (min-width: 500px) {
        padding: 20px;
        margin: 20px 0;
    }

    @media (min-width: 800px) {
        .heading {
            display: flex;
            margin-bottom: 15px;
            align-items: center;
        }
    }

    .desktop-map {
        display: none;
    }

    @media (min-width: 800px) {
        display: flex;
        align-items: start;

        .desktop-map {
            display: block;
            margin: 0 20px 0 0;
            border-radius: 4px;
            width: 200px;
        }

        .content {
            flex-grow: 1;
        }
    }

    .title {
        text-decoration: none;
        margin-right: auto;
    }

    h3 {
        color: $green;
        line-height: 1.4;
        font-size: 20px;
        margin: 0 90px 12px 0;

        @media (max-width: 400px) {
            font-size: 18px;
        }
        
        @media (min-width: 800px) {
            margin: 0;
            font-size: 24px;
        }
    }

    h4 {
        font-size: 16px;
        margin: 1em 0;
    }

    .contact-buttons {
        display: flex;
        border-top: 2px solid #f0f0f0;
        border-bottom: 2px solid #f0f0f0;
        margin: 0 -10px 10px;
        min-width: 220px;

        a {
            flex: 1 1 0;
            text-align: center;
            padding: 10px 5px 8px;
            font-size: 14px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: black;
            text-decoration: none;
            border-left: 2px solid #f0f0f0;

            &:first-child {
                border: none;
            }

            &:hover {
                background: #f8f8f8;
            }

            img {
                display: inline-block;
                vertical-align: middle;
                width: 16px;
                height: 16px;
                margin: 0 8px 4px 0;
            }
        }
            
        @media (min-width: 800px) {
            border: none;
            margin: 0;

            a, a:first-child {
                border: 2px solid #f0f0f0;
                border-radius: 4px;
                margin: 0 5px;
            }

            a:last-child {
                margin-right: 0;
            }
        }
    }

    .summary {
        display: flex;

        .mobile-map {
            flex: 0 0 auto;
            width: 100px;
            height: 100px;
            margin: 0 15px 0 0;
            background-color: #346dc2;
            border-radius: 6px;
            overflow: hidden;
            
            @media (max-width: 500px) {
                margin-right: 10px;
            }

            @media (min-width: 800px) {
                display: none;
            }
        }

        .info {
            display: flex;
            flex-wrap: wrap;
            line-height: 1.3;
            margin-top: -4px;
            flex-grow: 1;

            > div {
                border-bottom: 1px solid #e0e0e0;
                flex: 1 0 40%;
                padding: 4px 0;
            }

            h4 {
                font-size: 13px;
                font-weight: normal;
                color: #888;
                margin: 0;
            }

            p {
                margin: 0;
                font-size: 15px;
                font-weight: bold;
                color: #333;
            }
            
            .address {
                flex: 1 1 auto;
                width: 100%;
            }

            > div.time {
                border-left: 1px solid #e0e0e0;
                padding-left: 8px;
            }

            @media (min-width: 600px) {
                > div {
                    flex-basis: 0;
                }

                .job-number {
                    border-left: 1px solid #e0e0e0;
                    padding-left: 8px;
                }
            }

            @media (min-width: 800px) {
                border-top: 1px solid #e0e0e0;
            }
        }
    }

    .description {
        font-size: 15px;
        line-height: 1.4;
        margin: 1em 0
    }

    table {
        border-collapse: collapse;
        width: 100%;
        
        th, td {
            text-align: left;
            font-size: 14px;
            padding: 8px 0;
            line-height: 1;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
        }

        th {
            width: 11em;
            max-width: 50%;
            color: #111;
            font-weight: bold;
        }
    }

    .actions {
        display: flex;
        justify-content: right;
        flex-wrap: wrap;
    }

    .btn {
        display: block;
        margin: 10px 0 0;
        background-color: $green;
        color: white;
        text-transform: uppercase;
        text-align: center;
        padding: 8px 12px;
        border-radius: 4px;
        text-decoration: none;
        font-weight: bold;
        cursor: pointer;
        flex-grow: 1;
        box-sizing: border-box;

        &:hover {
            background-color: lighten($green, 8%);
        }

        @media (min-width: 500px) {
            width: 200px;
            margin: 20px 0 0;
            flex-grow: 0;
        }

        & + .btn {
            margin-left: 10px;
        }
    }

    @media (min-width: 800px) {
        .uploads {
            display: flex;

            > div {
                flex: 1 1 0;
            }

            > div + div {
                margin-left: 20px;
            }
        }
    }
}

.single-job .job h3 {
    font-size: 24px;
    line-height: 1.4;
}

.back-btn-container {
    margin: 20px 0;
}

.back-btn {
    display: inline-block;
    text-decoration: none;
    color: black;
    padding: 6px 10px;
    border-radius: 6px;
    color: #444;

    &:hover {
        background-color: rgba(255, 255, 255, 0.6);
    }

    .fas {
        margin: 0 8px 2px 0;
        vertical-align: middle;
    }
}

.job-status {
    text-transform: uppercase;
    font-size: 14px;
    padding: 5px 10px 6px;
    border-radius: 4px;
    background: #f0f0f0;
    text-align: center;
    position: absolute;
    top: 12px;
    right: 10px;
    margin: 0;

    &:last-child {
        margin-right: 0;
    }

    &.accepted {
        background: $green;
        color: white;
    }

    &.upcoming {
        background: #fa4;
        color: white
    }

    &.completed {
        background-color: darken($green, 10%);
        color: white;
    }

    &.overdue {
        background: #b44;
        color: white;
    }

    @media (min-width: 800px) {
        position: static;
        display: inline-block;
        margin: 0 10px;
    }
}