@import './constants.scss';

@font-face {
    font-family: FOCO;
    font-weight: normal;
    src: url(../fonts/Foco-Regular.woff) format("woff"),
        url(../fonts/Foco-Regular.woff2) format("woff2"),
        url(../fonts/Foco-Regular.ttf) format("ttf"),
        url(../fonts/Foco-Regular.eot) format("eot"),
        url(../fonts/Foco-Bold.svg) format("svg");
}

@font-face {
    font-family: FOCO;
    font-weight: bold;
    src: url(../fonts/Foco-Bold.woff) format("woff"),
        url(../fonts/Foco-Bold.woff2) format("woff2"),
        url(../fonts/Foco-Bold.ttf) format("ttf"),
        url(../fonts/Foco-Bold.eot) format("eot"),
        url(../fonts/Foco-Bold.svg) format("svg");
}

html, body {
    margin: 0;
    padding: 0;
}

body {
    background-color: $light-green;
    font-family: FOCO, Arial, Helvetica, sans-serif;
}

.list-empty, .p-error {
    text-align: center;
    margin: 40px 20px;
    line-height: 1.3;
}

.p-error {
    color: #b44;
    font-weight: bold;
}

h1 {
    font-size: 22px;
    text-align: center;
    margin: 40px 0;
    line-height: 1.4;
    color: $dark-green;

    @media (min-width: 800px) {
        font-size: 26px;
    }
}

.btn {
    display: inline-block;
    margin: 0 5px;
    padding: 7px 20px 8px;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    background: darken($green, 5%);
    color: white;
    border-radius: 4px;
    cursor: pointer;
    appearance: none;
    border: none;
    font-family: inherit;

    &:hover {
        background: $green;
    }

    &.outline {
        background: none;
        color: black;

        &:hover {
            background: none;
            color: #444;
        }
    }

    &.red {
        background: darken(#b44, 5%);
        &:hover {
            background: #b44;
        }
    }
}

.page-container {
    margin: 40px 10px;

    @media (min-width: 800px) {
        margin: 40px auto;
        padding: 0 40px;
        max-width: 900px;
    }
}

.page-container > div {
    position: relative;
    background-color: white;
    border-radius: 8px;
    margin: 10px 0;
    padding: 10px;
    box-shadow: 0 2px 16px rgba(109, 94, 94, 0.025);
}