@import './constants.scss';

$radius: 40px;

.page-header {
    position: relative;
    background-color: white;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    padding: 1px 0;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.025);
    
    .logo {
        display: block;
        width: 175px;
        margin: 30px auto 35px;
    }

    .logout-btn {
        position: absolute;
        top: 50px;
        right: 10px;
        width: 30px;
        height: 30px;
        background-image: url(../images/logout.png);
        background-size: 20px auto;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        font-size: 0;
    }

    ul.nav {
        margin: ($radius - 10px) auto;
        padding: 0 ($radius - 10px);
        max-width: 500px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        list-style: none;

        li {
            font-size: 14px;
            text-align: center;
            width: 100px;

            a {
                text-decoration: none;
                color: #666;

                &.active {
                    color: black;
                    font-weight: bold;

                    .icon {
                        background-color: $green;
                    }
                }
            }

            .icon {
                display: block;
                $radius: 20px;
                width: 2 * $radius;
                height: 2 * $radius;
                border-radius: $radius;
                border: 1px solid $green;
                margin: 0 auto 8px;
                overflow: hidden;
                background-size: $radius $radius;
                background-position: 50% 50%;
                background-repeat: no-repeat;
            }
        }
    }

    @media (min-width: 800px) {
        display: flex;
        padding: 0 40px;
        align-items: center;

        .logo {
            margin: 25px 0 30px;
        }

        ul.nav {
            margin: 0 20px 0 auto;
        }

        .logout-btn {
            top: 45px;
            right: 27px;
        }
    }
}