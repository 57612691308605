@import './constants.scss';

.login-page {
  min-height: 100vh;
  padding: 40px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  background-image: url(../images/login1.svg), url(../images/login2.svg);
  background-size: 35vh auto, 35vh auto;
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;

  .logo {
    width: 90%;
    max-width: 200px;
  }

  form {
    padding: 30px;
    width: 95%;
    max-width: 400px;
    box-sizing: border-box;

    input {
      border: 2px solid $green;
      width: 100%;
      padding: 11px 15px 10px;
      line-height: 20px;
      border-radius: 40px;
      margin: 5px 0;
      box-sizing: border-box;
      font-size: 13px;
      text-align: center;
      box-shadow: none;
      appearance: none;
      -webkit-appearance: none;
      
      &:focus {
        outline: none;
        border: 2px solid darken($green, 20%);
      }

      &[type="submit"] {
        display: block;
        width: auto;
        margin: 10px auto 0;
        padding: 11px 30px 10px;
        color: white;
        background-color: $green;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }
    }
  }

  h3 {
    font-size: 22px;
    margin: 1em 0;
    text-align: center;
  }

  p {
    font-size: 15px;
    line-height: 1.3;
    margin: 1.4em 0;
    text-align: center;
    color: #444;

    &.error {    
      color: #b44;
    }
  }
}