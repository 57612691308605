@import './constants.scss';

.need-help {
  margin: -20px 0 0 0;
  padding: 40px;
  text-align: center;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0.5) 25%, rgba(255,255,255,0) 100%);
  font-size: 16px;

  p {
    margin: 0.5em 0;
    line-height: 1.3;
  }

  p:first-child {
    font-size: 1.5em;
    font-weight: bold;
    color: $dark-green;
  }

  @media (min-width: 800px) {
    font-size: 18px;
  }
}