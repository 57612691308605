.cancel-job {
  text-align: center;

  textarea {
    width: 90%;
    height: 200px;
    box-sizing: border-box;
    margin: 20px auto 0;
    font-family: sans-serif;
    padding: 16px;
    font-size: 15px;
  }

  .actions {
    margin: 20px 0 30px;
    text-align: center;
  }
}