@import '../constants.scss';

.entity-form {
  margin: 60px auto;
  width: 95%;
  max-width: 1000px;
  box-sizing: border-box;

  .body {
    background-color: white;
    border-radius: 6px;
    padding: 20px;
    overflow: hidden;
  }

  .form-title {
    margin: -20px -20px 20px -20px;
    padding: 12px 18px;
    background: #444;
    color: white;
    border-bottom: 2px solid #333;
  }

  h1 {
    font-size: 28px;
    line-height: 1.4;
    margin: 0 0 0.7em;
    text-align: left;

    .empty {
      color: #888;
    }
  }

  .buttons {
    margin: 15px 0 0;
    text-align: right;

    .btn:last-child {
      margin-right: 0;
    }
  }

  .job-status {
    float: right;
  }
}

.input-row {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;

  > * {
    flex: 1 1 100px;
  }

  .input {
    padding-right: 15px;
    box-sizing: border-box;

    & + .input {
      border-left: 1px solid #e0e0e0;
      padding-left: 15px;
    }
  }

  label {
    display: block;
    padding: 8px 0 0;
    font-size: 14px;
    color: #666;
  }

  .error {
    float: right;
    color: #b22;
  }

  input, textarea, select {
    display: block;
    background: none;
    border: none;
    appearance: none;
    padding: 7px 0;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    box-sizing: border-box;
    font-family: sans-serif;

    &:focus {
      outline: none
    }
  }

  textarea {
    height: 8em;
    min-height: 8em;
    min-width: 100%;
    max-width: 100%;
    resize: vertical;
  }

  & + & {
    border-top: none;
  }
}